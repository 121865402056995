<!--
 * @Author: your name
 * @Date: 2020-06-07 13:44:13
 * @LastEditTime: 2020-07-22 11:31:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \src\views\login\register.vue
--> 
<template>
  <div class="register">
    <div class="main">
      <!--注册框-->
      <div class="left">
        <!--titile-->
        <h1>创建用户</h1>
        <p class="sm">为您加快结算流程,便于查看订单以及管理订单等</p>
        <p class="account">
          <span>已有伊莉丝账户</span>
          <span @click="dl()">登录 ></span>
        </p>
        <!--信息-->
        <div class="int">
          <input type="text" placeholder="称呼" v-model="name" />
          <input type="password" placeholder="密码" v-model="password" class="psd" />
          <div>
            <input
              type="text"
              placeholder="手机号码"
              v-model="tel"
              @input="()=>{this.tel=this.tel.replace(/[^\d]/g,'') ,this.tel = this.tel.substr(0,11),input()}"
            />
            <button @click="fend()">{{s == 0?"发送验证码":s + 's'+ '后重新获取'}}</button>
          </div>
          <input
            type="number"
            placeholder="验证码"
            v-model="code"
            @input="()=>{this.code=this.code.substr(0,4)}"
            class="number"
          />
          <el-input placeholder="请填写邮箱地址" v-model="mail" @blur="sendEmail" class="emil"></el-input>
        </div>
        <!--选项-->
        <div class="select">
          <!--性别-->
          <p>性别（可选）</p>
          <el-radio v-model="radio" label="1">男</el-radio>
          <el-radio v-model="radio" label="2">女</el-radio>
        </div>
        <!--生日-->
        <div class="fete">
          <p>生日（可选）</p>
          <div class="block">
            <el-date-picker
              v-model="value1"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </div>
        </div>
        <p class="erros">{{erros}}</p>
        <!--条款-->
        <div class="clause">
          <div @click="check()">
            <img src="@/assets/images/对号s.png" alt v-if="yes" />
          </div>
          <p>我已阅读并同意伊莉丝钻石
             <span @click="$router.push('/privacypolicy')">隐私政策</span>和
            <span @click="$router.push('/orderargument')">条款细则</span>
          </p>
        </div>
        <!--注册-->
        <button class="establish" @click="establish()">创建账户</button>
      </div>
      <!--图片-->
      <div class="right">
        <div class="img">
          <img src="@/assets/images/tu.png" alt />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { phoneCode, register } from "@/service/api.js";
export default {
  data() {
    return {
      name: "", //姓名
      tel: "", //电话
      code: "", //验证码
      mail: "", //邮箱
      yes: false, //是否勾选
      value1: "", //日期
      erros: "", //错误信息
      radio: "1", //单选框值
      password: "", //密码
      phoneStatus: 1, //倒计时
      s: 0, //倒计时,
      fullscreenLoading: false
    };
  },
  methods: {
    // 清空
    input() {
      if (this.tel == "") {
        this.erros = "";
      }
    },
    // 跳转登录
    dl() {
      this.$router.push({
        path: "/createLogin.html"
      });
    },
    // 发送验证码
    fend() {
      let res = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (res.test(this.tel)) {
        if (this.phoneStatus == 2) {
          return;
        }
        this.erros = "";
        phoneCode({ mobile: this.tel, type: 1 }).then(res => {
          console.log(res);

          if (res.status != 200) {
            this.erros = res.message;
          }
          this.phoneStatus = 2;
          this.startTime();
        });
      } else {
        this.erros = "手机号格式不正确";
      }
    },
    //  对号
    check() {
      this.yes = !this.yes;
    },
    // 创建用户
    establish() {
      var regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (this.yes == false) {
        this.erros = "请勾选隐私政策及收集相关个人信息";
      } else if (this.password.length < 6) {
        this.erros = "请输入6位或6位以上密码";
      } else if (this.tel == "") {
        this.erros = "手机号不能为空";
      } else if (this.mail == "" && !regEmail.test(this.mail)) {
        this.erros = "邮箱输入不正确";
      } else if (this.name == "") {
        this.erros = "用户名不能为空";
      } else if (this.code == "") {
        this.erros = "请输入验证码";
      } else if (this.value1 == "") {
        this.erros = "请选择生日";
      } else {
        this.erros = "";
        register({
          name: this.name,
          pwd: this.password,
          mobile: this.tel,
          code: this.code,
          gender: this.radio,
          emali: this.mail,
          birthday: this.value1.toString()
        }).then(res => {
          console.log(res);
          if (res.status == 400) {
            this.erros = res.message;
          } else {
            const loading = this.$loading({
              lock: true,
              text: "Loading",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)"
            });
            setTimeout(() => {
              loading.close();
              this.$router.push({
                path: "/createLogin.html"
              });
            }, 300);
          }
        });
      }
    },
    //倒计时
    startTime() {
      this.s = 60;
      let time = setInterval(() => {
        if (this.s < 2) {
          clearInterval(time);
          this.phoneStatus = 3;
        }
        this.s--;
      }, 1000);
    },
    //邮箱
    sendEmail: function() {
      var regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (this.mail == "" && !regEmail.test(this.mail)) {
        this.erros = "邮箱输入不正确";
      }
      this.erros = "";
    }
  }
};
</script>
<style lang="less" scoped>
.register {
  width: 80%;
  margin: 0 auto;
  border-top: 1px solid #f5f5f5;
  margin-bottom: 200px;
  min-width: 900px;
}
.main {
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .left {
    width: 40%;
  }
  .right {
    width: 54%;
    height: 100%;
    .img {
      overflow: hidden;
      width: 100%;
      height: 714px;
      margin-top: 146px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.left {
  h1 {
    font-weight: 400;
    font-size: 30px;
    margin: 35px 0;
  }
  .sm {
    margin: 16px 0;
    font-size: 14px;
  }
  .account {
    width: 40%;
    font-size: 14px;
    margin: 18px 0;
    display: flex;
    justify-content: space-between;
    span:last-child {
      color: chocolate;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.int {
  width: 100%;
  input {
    display: block;
    width: 98%;
    margin: 20px 0;
    height: 30px;
    padding-left: 5px;
    border: none;
    outline: none;
    border-bottom: 1px solid #ccc;
    &:last-child {
      margin: 40px 0;
    }
  }
  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      width: 60%;
    }
    button {
      width: 145px;
      height: 32px;
      font-size: 14px;
    }
  }
}
.select {
  width: 100%;
  margin: 20px 0;
  p {
    font-size: 14px;
    color: #999999;
    margin: 10px 5px;
  }
  label {
    display: block;
    font-size: 14px;
    height: 25px;
    color: #999999;
    margin: 0 5px;
    span {
      padding-left: 10px;
    }
  }
}
.fete {
  width: 100%;
  p {
    font-size: 14px;
    color: #999999;
    margin: 0 5px;
  }
}
select {
  border: none;
}
.clause {
  width: 100%;
  height: 60px;
  margin: 25px 0;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  div {
    width: 16px;
    height: 16px;
    border: 1px solid #000;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  p {
    font-size: 14px;
    color: #999999;
    span:hover{
      cursor: pointer;
      color: #333;
    }
  }
}
.establish {
  width: 100%;
  height: 62px;
  border: none;
  outline: none;
  background: #921d22;
  color: #ffff;
}
.mess {
  color: red;
  font-size: 14px;
  height: 25px;
  margin-top: 10px;
}
.block /deep/ .el-input {
  width: 100%;
  margin-top: 20px;
  border: none;
  border-bottom: 1px solid #ccc;
}
.block /deep/ .el-input__inner {
  border: none;
}
.block /deep/ .el-input__inner .el-date-table td.current:not(.disabled) span {
  background: #921d22;
}
.number {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }
}
.erros {
  color: #921d22;
  font-size: 14px;
  height: 20px;
}
.psd {
  padding-top: 12px;
}
.el-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
}
.emil /deep/ .el-input__inner:first-child {
  border: none;
  padding-left: 5px;
}
</style>